<nav class="navbar navbar-inverse navbar-tophead navbar-fixed-top" role="navigation" [hidden]="isHidden">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="btn btn-success navbar-toggle collapsed" data-toggle="collapse"
        data-target="#app-navbar" aria-expanded="false">
        <span class="glyphicon glyphicon-chevron-down"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="app-navbar">
      <ul class="nav navbar-nav navbar-nav-tophead">
        <li class="pointer" *ngFor="let item of menuItems" (click)="item.action()">
          <a class="title" [title]="item.hover">
            <i [class]="item.icon"></i> {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>