import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DonorService } from "../donor.service";
import { ControlContainer, UntypedFormControl, FormGroup, NgForm } from "@angular/forms";
import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "app-donor",
  templateUrl: "./donor.component.html",
  styleUrls: ["./donor.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DonorComponent implements OnInit {
  @Input() donorEditCommon: DonorService;
  @Input() form: NgForm;
  @Output() onNameChange = new EventEmitter<string>();
  private formStarted = false; 

  constructor(private _googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {}

  showError(ctl: UntypedFormControl){
    return this.form && ctl && (this.form.submitted || ctl.touched || ctl.dirty);
  }

  onFormFieldFocus() {
    if (!this.formStarted) {
      this._googleAnalytics.customEventEmitter({
        eventName: 'Splash_form_donor_started',
        eventCategory: 'Form',
        eventAction: 'Start',
        eventLabel: 'User began filling out the donor form'
      });
      this.formStarted = true;
    }
  }
}
