import { Component, ElementRef, Input, ViewChild } from "@angular/core";

import braintreeClient from "braintree-web/client";
import braintreeUSB from "braintree-web/us-bank-account";
import * as dropin from "braintree-web-drop-in";
import * as dataCollector from "braintree-web/data-collector";
import { RecaptchaComponent } from 'ng-recaptcha';
import * as Enums from "../domain/enums";

import {
  DonationAmount,
  IInHonorBindingModel,
  IGiftNotificationBindingModel,
  IDonorTransactionBindingModel,
  ICampaignBindingModel,
  IInHonorNotificationBindingModel,
  IAddressDataBinding,
} from "../domain/types";
import { DonorBindingModel } from "../domain/models";
import {
  TransactionTypeEnum,
  ChargeFrequencyEnum,
  CreditCardTypeEnum,
} from "../domain/enums";
import { CommonService } from "../common/common.service";
import { DataService } from "../services/data.service";
import { EventBusService, EventsEnum } from "../common/event-bus.service";
import { ConfigService } from "../config.service";
import { CsrService } from "../csr/csr.service";
import { DonorService } from "../donor.service";
import { DonationFrequencyService } from "../donation-frequency.service";
import { DonorTransactionBindingModel } from "../domain/models";
import { LanguageService } from "../common/language.service";
import { LoggerService } from "../common/logger.service";
import { PaymentDetailsComponent } from "../payment-details/payment-details.component";
import { HonorComponent } from "../honor/honor.component";
import { Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationCancel } from "@angular/router";
import { NgForm } from "@angular/forms";
import { catchError, map, switchMap } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import { LocalStorageService } from "../common/local-storage.service";
import { BaseComponent } from "../base.component";
@Component({
  selector: "app-donate",
  templateUrl: "./donate.component.html",
  styleUrls: ["./donate.component.scss"],
})
export class DonateComponent extends BaseComponent {
  private _currentDonorId: number = -1;
  private _hostedFieldsInstance;
  private _dropinInstance;
  private _deviceDataCollector;
  static readonly _applePayDonationLabel = "Donation";

  donate: DonationAmount = "Other";
  language = "en-US";
  gettingCampaign;
  donationAmounts;
  donation;
  enableDonation = true;
  dedicateMyGift = false;
  accountNameChanged = false;
  showVerifyModal = false;
  showVerifyCCModal = false;
  showVerifyeCheckPaymentModal = false;
  showConfirmCreditCardECheckPaymentModal = false;
  showFailedCCECheckModal = false;
  honorModel = {} as IInHonorBindingModel;
  giftNotify = {} as IGiftNotificationBindingModel;
  transaction: IDonorTransactionBindingModel =
    new DonorTransactionBindingModel();
  imageUrl: string = "";
  selectedRecord: string = "";
  item: IDonorTransactionBindingModel;
  typeOut: string;
  frequency: string;
  braintree: any;
  _usBankAccountInstance: any;
  showVerifyAddressModal: boolean;
  campaignId: number;
  transactionComplete: boolean;
  recaptchaSiteKey: string = this._config.recaptchaSiteKey;
  recaptchaToken: string;

  @Input() drtv = false;

  @ViewChild('completeGiftBtn') saveDonor: ElementRef;
  @ViewChild('honorForm', { read: ElementRef }) honorFormRef: ElementRef;
  @ViewChild('donorForm', { read: ElementRef }) donorFormRef: ElementRef;
  @ViewChild('paymentDetailsForm', { read: ElementRef }) paymentDetailsFormRef: ElementRef;
  @ViewChild('giftAmount', { read: ElementRef }) giftAmountRef: ElementRef;
  @ViewChild(PaymentDetailsComponent) paymentDetails: PaymentDetailsComponent;
  @ViewChild(HonorComponent) honorDetails: HonorComponent;
  @ViewChild("donateForm") donateForm: NgForm;
  nameOnCard: string;
  defaultDonationAmount: number;
  isCCFormReady = false;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  constructor(
    private _common: CommonService,
    private _data: DataService,
    private _eventBus: EventBusService,
    private _config: ConfigService,
    private _donationFrequency: DonationFrequencyService,
    private _language: LanguageService,
    private _logger: LoggerService,
    private _googleAnalytics: GoogleAnalyticsService,
    private _route: ActivatedRoute,
    private _localStorageService: LocalStorageService,
    public csr: CsrService,
    public donorEditCommon: DonorService,
    public router: Router,
    private elementRef: ElementRef
  ) {
    super();
    this._getCampaign();

    const campaignId = this.csr.getCampaignId();
    const donationAmount = this.csr.donationAmount;

    if (!this.csr || campaignId === 0) {
      _logger.logError({ message: "No campaign Id" });
      this._common.$location.path("/").replace();
    }
    const newDonor = new DonorBindingModel();
    newDonor.hospitalId = this.csr.getHospitalId();
    newDonor.campaignId = campaignId;
    newDonor.languageValue = this._language.language;

    this.language = this._language.language;

    this.honorModel.campaignId = campaignId;

    this.giftNotify.campaignId = campaignId;

    this.transaction.expirationMonth = 1;
    this.transaction.expirationYear = new Date().getFullYear();
    if (donationAmount !== 0) {
      this.transaction.donationAmount = donationAmount;
    }

    this.donorEditCommon.donor = newDonor;

    this.toggleCardName();

    this.addSubscription(
      this._eventBus.on(EventsEnum.LanguageChanged).subscribe((data) => {
        this.startBraintree(this._language.language);
      })
    );
  }

  protected _afterComponentInit(): void {
    this.addSubscription(
      this._common.showConfirmCreditCardECheckPaymentModal$.subscribe(
        (value) => {
          this.showConfirmCreditCardECheckPaymentModal = value;
        }
      )
    );
  }

  protected _afterComponentDestroy(): void {
    this.showConfirmCreditCardECheckPaymentModal = false;
  }

  get notifySomeone() {
    return this.honorDetails?.notifySomeone || false;
  }

  get inMemoryHonorOf() {
    return this.honorDetails?.inMemoryHonorOf || false;
  }

  get paymentType() {
    return this.paymentDetails?.paymentType;
  }

  get isFormValid() {
    const hasErrors = this.hasFormErrors;
    const requiresPaymentValidation = !this.isPaymentMethodRequestable;
  
    if (hasErrors || requiresPaymentValidation) {  
      if (requiresPaymentValidation && this.paymentType === TransactionTypeEnum.CreditCard) {
        this.validateCreditCardFields();
      }

      this.scrollIntoView();

      return false;
    }
  
    return true;
  }

  private get hasFormErrors(): boolean {
    return this.donateForm.invalid || this.paymentDetails.hasErrors || this.honorDetails.hasErrors;
  }

  private async validateCreditCardFields(): Promise<void> {
    if (!this._dropinInstance) {
      return;
    }
  
    try {
      await this.requestPaymentMethod();
      this.hideSheetError();
    } catch (error) {
      console.error(error);
    }
  }

  private requestPaymentMethod(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._dropinInstance.requestPaymentMethod((err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
  
      this._dropinInstance.on('paymentMethodRequestable', () => {
        this.hideSheetError();
      });
    });
  }
  
 private hideSheetError(): void {
  const sheetError = document.querySelector('[data-braintree-id="sheet-error"]') as HTMLElement;
  const cardError = document.querySelector('[data-braintree-id="card"]') as HTMLElement;

  if (sheetError && cardError) {
    sheetError.style.display = 'none';
    cardError.style.border = 'none';
  }
}

  get isPaymentMethodRequestable(): boolean {
    return !(this.paymentType === TransactionTypeEnum.CreditCard &&
      !this._dropinInstance.isPaymentMethodRequestable());
  }

  scrollIntoView(): void {
    let elementRef = this.elementRefName;

    if (elementRef) {
      elementRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: "start",
        inline: "nearest",
      });
    }
  }

  get elementRefName(): ElementRef | undefined {
    let elementRef;
    if (this.donateForm.invalid) {
      elementRef = this.verifyInvalidFields();
    } else if (this.paymentDetails.hasErrors) {
      elementRef = this.donorFormRef;
    } else if (this.honorDetails.hasErrors) {
      elementRef = this.honorFormRef;
    } else if (!this.isPaymentMethodRequestable) {
      elementRef = this.paymentDetailsFormRef;
    } else if (this.transaction.donationAmount <= 0) {
      elementRef = this.giftAmountRef;
    }
    return elementRef;
  }

  verifyInvalidFields(): ElementRef {
    if (this.donateForm.form.get('bankName') && this.donateForm.form.get('bankName').status == "INVALID" ||
      this.donateForm.form.get('bankNumber') && this.donateForm.form.get('bankNumber').status == "INVALID" ||
      this.donateForm.form.get('routerNumber') && this.donateForm.form.get('routerNumber').status == "INVALID") {
      return this.paymentDetailsFormRef;
    } else {
      return this.donorFormRef;
    }
  }

  onNameChange() {
    if (this.paymentDetails.accountNameChanged) {
      if ((this.transaction.accountPersonName || "").trim() !== "") {
        return;
      }
    }
    if (
      this.donorEditCommon.donor.firstName &&
      this.donorEditCommon.donor.lastName
    ) {
      this.nameOnCard = `${this.donorEditCommon.donor.firstName} ${this.donorEditCommon.donor.lastName}`;
      this.transaction.accountPersonName = this.nameOnCard;
      this.paymentDetails.accountNameChanged = false;
    }
  }

  brainTreeFieldErrors: { [key: string]: boolean } = {};

  toggleBrainTreeValidation() {
    //console.log(this._hostedFieldsInstance);
    if (this._hostedFieldsInstance?._fields) {
      for (let p in this._hostedFieldsInstance._fields) {
        const field = this._hostedFieldsInstance._fields[p];
        //console.log(field);
        this.toggleBrainTreeFormError(
          `#${p}ErrorMessage`,
          this.brainTreeFieldErrors[p]
        );
      }
    }
  }

  toggleBrainTreeFormError = (id: string, add = false) => {
    const $el = $(id).siblings(".form-control");
    if (add) {
      $el.addClass("cmn-form-control-has-error");
      if ($(id).text().trim() === "") {
        $(id).text("This field is required");
      }
    } else {
      $el.removeClass("cmn-form-control-has-error");
    }
  };

  startBraintree(locale) {
    const self = this;
    const toggleFormError = (id: string, add = false) => {
      self.toggleBrainTreeFormError(id, add);
    };

    const englishCustomTranslations = {
      chooseAWayToPay: 'Select Payment Method',
      Card: 'Credit or Debit Card'
    }

    const spanishCustomTranslations = {
      chooseAWayToPay: 'Selecciona un método de pago',
      Card: 'Tarjeta de crédito o débito'
    }

    this._data.Braintree.getClientToken(true).subscribe(
      (response) => {
        if (this._dropinInstance) {
          this._dropinInstance.teardown();
        }

        dropin.create(
          {
            authorization: response.token,
            container: "#dropin-container",
            translations: locale == "en-US" ? englishCustomTranslations : spanishCustomTranslations,
            venmo: {
              allowDesktopWebLogin: true,
              paymentMethodUsage: 'multi_use',
              allowNewBrowserTab: false,
              profileId: response.profileId
            },
            locale: locale == "en-US" ? "en_US" : "es_ES",
            card: {
              cardholderName: { required: true },
              overrides: {
                fields: {
                  cvv: {
                    placeholder: 'CVV'
                  },
                  postalCode: null,
                },
              },
            },
            paypal: {
              flow: 'vault'
            },
            applePay: {
              displayName: 'CMN Hospitals',
              paymentRequest: {
                total: {
                  label: DonateComponent._applePayDonationLabel,
                  amount: this.transaction.donationAmount ?? '1.00',
                },
                requiredBillingContactFields: ['postalAddress']
              }
            }
          },
          (dropinErr, dropinInstance) => {
            if (dropinErr) {
              this._common.logger.logError({
                message: `Could not create the Braintree client instance<br />${dropinErr}`,
              });
              return;
            }

            this._dropinInstance = dropinInstance;
            if (dropinInstance.isPaymentMethodRequestable()) {
              this.saveDonor.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }

            dropinInstance.on('paymentMethodRequestable', (event) => {
              if (event.paymentMethodIsSelected)
                this.saveDonor.nativeElement.scrollIntoView({ behavior: 'smooth' });
            });
          }
        );

        braintreeClient.create(
          {
            authorization: response.token,
          },
          (createErr, clientInstance) => {
            if (createErr) {
              this._common.logger.logError({
                message: `Could not create the Braintree client instance<br />${createErr}`,
              });
              return;
            }

            braintreeUSB.create(
              {
                client: clientInstance,
              },
              (usBankAccountErr, usBankAccountInstance) => {
                if (usBankAccountErr) {
                  this._common.logger.logError({
                    message: `Unable to initialize US Bank Account instance<br />${usBankAccountErr}`,
                  });
                } else {
                  this._usBankAccountInstance = usBankAccountInstance;
                }
              }
            );

            dataCollector.create(
              {
                client: clientInstance,
                paypal: true,
              },
              (dataCollectorError, dataCollectorInstance) => {
                if (dataCollectorError) {
                  this._common.logger.logError({
                    message: `Unable to initialize device data collector instance<br />${dataCollectorError}`,
                  });
                } else {
                  this._deviceDataCollector = dataCollectorInstance;
                }
              }
            );
          }
        );
      },
      (error) => {
        this._common.logger.logError({
          message: `Could not get the Braintree token<br />${this._common.jsonMessage(
            error
          )}`,
        });
      }
    );
  }

  rowClass(value1, value2) {
    if (value1 === undefined || value1 === "") {
      value1 = null;
    }
    if (value2 === undefined || value2 === "") {
      value2 = null;
    }
    if (value1 !== value2) {
      return "differentAddressElement";
    }
    return "";
  }

  wf1MaskAccount(value) {
    if (!value) {
      return "";
    }
    if (value.length > 4) {
      return "*" + value.substring(value.length - 4, value.length);
    }
    return "*" + value;
  }

  handleCloseEditCityModal() {
    const record: IAddressDataBinding = this.donorEditCommon.cities.find(
      (c) => `${c.recordId}` === `${this.selectedRecord}`
    );

    if (!record) return;

    this.donorEditCommon.donor.city = record.city;
    this.donorEditCommon.donor.stateId = record.stateId;
    this.donorEditCommon.donor.stateAbrev = record.stateAbrev;

    this.donorEditCommon.cities.splice(0, this.donorEditCommon.cities.length);
  }

  handleCloseVerifyModal(confirmed: boolean) {
    this.showVerifyModal = false;
    this.showVerifyCCModal = false;
    if (confirmed) {
      this._sendTransaction();
    }
  }

  handleOpenVerifyModal() {
    this.showVerifyModal = true;
    this.showVerifyCCModal = this.paymentDetails.paymentType === TransactionTypeEnum.CreditCard;
  }

  handleCloseCreditCardECheckPaymentModal() {
    this.showConfirmCreditCardECheckPaymentModal = false;
    this.continueWithCall(false);
  }

  handleOpenCreditCardECheckPaymentModal() {
    this.showConfirmCreditCardECheckPaymentModal = true;
  }

  handleCloseVerifyeCheckPaymentModal(confirmed = false) {
    this.showVerifyeCheckPaymentModal = false;
    if (confirmed) {
      this._sendECheckTransaction();
    }
  }

  handleOpenVerifyeCheckPaymentModal() {
    this.showVerifyeCheckPaymentModal = true;
  }

  handleCloseFailedCCECheckModal() {
    this.showFailedCCECheckModal = false;
  }

  handleOpenFailedCCECheckModal() {
    this.showFailedCCECheckModal = true;
  }

  handleCloseVerifyAddressModal(reason) {
    this.showVerifyAddressModal = false;
    const state = reason !== '' ? reason : 'closed';
    this.trackModalEvent(state, `user ${state} the modal`);
    if (reason === "ignore") {
      this.doSaveDonor();
      return;
    }
    if (reason === "accept") {
      this.donorEditCommon.donor.address1 =
        this.donorEditCommon.melissaData.address1;
      this.donorEditCommon.donor.address2 =
        this.donorEditCommon.melissaData.address2 || "";
      this.donorEditCommon.donor.city = this.donorEditCommon.melissaData.city;
      this.donorEditCommon.donor.state = this.donorEditCommon.melissaData.state;
      this.donorEditCommon.donor.stateId =
        this.donorEditCommon.melissaData.stateId;
      this.donorEditCommon.donor.postalCode =
        this.donorEditCommon.melissaData.postalCode;
      this.doSaveDonor();
      return;
    }
  }

  handleOpenVerifyAddressModal() {
    this.showVerifyAddressModal = true;
  }

  handleDonationChanged() {
    if (this._dropinInstance?.getAvailablePaymentOptions().includes('applePay')) {
      this._dropinInstance.updateConfiguration('applePay', 'paymentRequest', {
        total: {
          label: DonateComponent._applePayDonationLabel,
          amount: this.transaction.donationAmount,
        },
        requiredBillingContactFields: ['postalAddress']
      });
    }
  }

  handleChargeFrequencyToggle() {
    this.transaction.chargeFrequency =
      this.transaction.chargeFrequency === ChargeFrequencyEnum.Monthly
        ? ChargeFrequencyEnum.OneTime
        : ChargeFrequencyEnum.Monthly;
  }

  handleAnonymousToggle() {
    this.transaction.displayInDash = !this.transaction.displayInDash;
  }

  private _getCampaign() {
    this.gettingCampaign = true;

    return this._route.paramMap
      .pipe(
        switchMap((paramsMap) => {
          // attempt to get/save campaign and hospital ids from url
          // if not provided, use cached ids
          let campaignId = parseInt(paramsMap.get("cid"));
          let hospitalId = parseInt(paramsMap.get("hid"));

          if (isNaN(campaignId) === false) {
            this._localStorageService.set("lastCampaignId", campaignId);
            if (isNaN(hospitalId)) {
              hospitalId = 0;
            }
            this._localStorageService.set("lastHospitalId", hospitalId);
            this.csr.initialize();
          }

          return this._data.Campaign.getForCsr(this.csr.getCampaignId());
        })
      )
      .subscribe(
        (result) => {
          const campaign = <ICampaignBindingModel>result;
          this.language = campaign.languageValue;
          this._eventBus.emit({
            name: EventsEnum.LanguageChanged,
            data: campaign.languageValue,
          });
          this.imageUrl = `${this._common.serviceUrl(
            this._config.apiServices.campaign
          )}SplashImage/${this.csr.getCampaignId()}`;

          this.donationAmounts = [campaign.donationAmount];

          this["donate"] = campaign.donationAmount;
          this.defaultDonationAmount = campaign.donationAmount;
          const otherAmts = (campaign.additionalSuggestedAmounts || "").split(
            ","
          );

          //Set initial value
          this.transaction.donationAmount = campaign.donationAmount || 0;

          if (this.drtv) {
            this.transaction.chargeFrequency = ChargeFrequencyEnum.Monthly;
          }

          otherAmts.forEach((amt, i) => {
            let v = parseFloat(amt || "");
            if (v && this.donationAmounts.indexOf(v) === -1) {
              this.donationAmounts.push(v);
            }
          });

          const obsList: Observable<any>[] = [];

          if (this.csr.getHospitalId()) {
            obsList.push(this._getHospital());
          }
          if (this.csr.radioStationId) {
            obsList.push(this._getRadioStation());
          }

          if (obsList.length > 0) {
            combineLatest(obsList).subscribe(
              (rtn) => {
                this.enableDonation = true;
                this.gettingCampaign = false;
              },
              (error) => {
                this.enableDonation = false;
                this.gettingCampaign = false;
              }
            );
          } else {
            this.enableDonation = true;
            this.gettingCampaign = false;
          }
        },
        (error) => {
          this._logger.logError({
            message: `Could not get the Campaign<br />${this._common.jsonMessage(
              error
            )}`,
          });
          this.enableDonation = false;
          this.gettingCampaign = false;
        }
      );
  }

  private _getHospital() {
    return this._data.getHospital(this.csr.getHospitalId()).pipe(
      map((hospital) => {
        this.csr.setHospitalName(hospital.hospitalName);
        return hospital;
      }),
      catchError((error) => {
        const htmlMessage = this._common.jsonMessage(error);
        this._logger.logError({
          message: `Could not get the Hospital<br />${htmlMessage}`,
        });
        this.enableDonation = false;
        throw error;
      })
    );
  }

  private _getRadioStation() {
    return this._data.getRadioStation(this.csr.radioStationId).pipe(
      map((radioStation) => {
        return radioStation;
      }),
      catchError((error) => {
        const htmlMessage = this._common.jsonMessage(error);
        this._logger.logError({
          message: `Could not get the Radio Station<br /> ${htmlMessage}`,
        });
        this.enableDonation = false;
        throw error;
      })
    );
  }

  getBankName() {
    if (this.transaction.abaNumber === undefined) {
      return;
    }
    this._data.Donor.getBankFromRouting(this.transaction.abaNumber).subscribe(
      (result) => {
        var routingBankName = <string>result;
        this.transaction.bankName = routingBankName;
      },
      console.error
    );
  }

  // creditCardClicked() {
  //   this.paymentType = 1;
  // }

  // eCheckClicked() {
  //   this.paymentType = 2;
  // }

  dedicateMyGiftClicked() {
    if (!this.dedicateMyGift) {
      this.dedicateMyGift = false;
    } else {
      this.dedicateMyGift = true;
    }
  }

  amountListClicked() {}

  toggleCardName() {
    if (
      this.transaction &&
      !this.accountNameChanged &&
      this.donorEditCommon.donor.firstName &&
      this.donorEditCommon.donor.lastName
    ) {
      this.transaction.accountPersonName = `${this.donorEditCommon.donor.firstName} ${this.donorEditCommon.donor.lastName}`;
    }
  }

  toggleSpinner(show = false) {
    this._eventBus.emit({ name: EventsEnum.SpinnerToggle, data: show });
  }

  save() {
    this.donorEditCommon.inHonorRequested(false);
    if (!this.isFormValid) {
      this._common.toast.warning(
        "Please make sure all required fields are filled in"
      );
      return;
    }

    if (this.transaction.donationAmount <= 0) {
      this.scrollIntoView();
      this._common.toast.warning("Please enter Donation Amount");
      return;
    }

    this.captchaRef.execute();

    if (this._currentDonorId !== -1) {
      if (this.paymentType === TransactionTypeEnum.ECheck) {
        this.submitECheck();
      }
      else {
        this._dropinInstance.requestPaymentMethod(
          (requestPaymentMethodErr, payload) => {
            if (requestPaymentMethodErr) {
              this._logger.logError({
                message: `Could not confirm payment information details<br />${requestPaymentMethodErr}`,
              });
            } else {
              this.submitDropInUIPayment(payload);
            }
          }
        );
      }
    } else {
      this.donorEditCommon.verifyAddress().subscribe((retVal) => {
        if (!retVal.validAddress) {
          this.donorEditCommon.melissaData.address1 = retVal.address1;
          this.donorEditCommon.melissaData.address2 =
            retVal.address2 === "" ? null : retVal.address2;
          this.donorEditCommon.melissaData.city = retVal.city;
          this.donorEditCommon.melissaData.state = retVal.state;
          this.donorEditCommon.melissaData.stateId = retVal.stateId;
          this.donorEditCommon.melissaData.postalCode = retVal.postalCode;

          this.handleOpenVerifyAddressModal();
        } else {
          this.doSaveDonor();
        }
      });
    }
  }

  doSaveDonor() {
    this.donorEditCommon.saveDonor(this.recaptchaToken).subscribe((retVal) => {
      if (!retVal) {
        return;
      }
      this._currentDonorId = retVal.donorId;
      this.csr.donorId = retVal.donorId;
      this.csr.donorSeed = retVal.donorSeed;
      this.csr.donorFirstName = retVal.firstName;
      this.csr.donorLastName = retVal.lastName;
      this.csr.setHospitalId(retVal.hospitalId);

      if (this.paymentType === 1) {
        this.toggleSpinner(true);

        this._dropinInstance.requestPaymentMethod(
          (requestPaymentMethodErr, payload) => {
            this.toggleSpinner();
            if (requestPaymentMethodErr) {
              this._logger.logError({
                message: `Could not confirm payment information details<br />${requestPaymentMethodErr}`,
              });
            } else {
              this.submitDropInUIPayment(payload);
            }
          }
        );
      } else {
        this.submitECheck();
      }
    });
  }

  submitDropInUIPayment(payload) {
    // values for the payload.type property found here: https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html#requestPaymentMethod
    switch (payload.type) {
      case 'CreditCard':
        this.paymentDetails.paymentType = Enums.TransactionTypeEnum.CreditCard;
        break;
      case 'PayPalAccount':
        this.paymentDetails.paymentType = Enums.TransactionTypeEnum.PayPal;
        break;
      case 'VenmoAccount':
        this.paymentDetails.paymentType = Enums.TransactionTypeEnum.Venmo;
        break;
      case 'ApplePayCard':
        this.paymentDetails.paymentType = Enums.TransactionTypeEnum.ApplePay;
        break;
      default:
        this._logger.logError({
          message: `${payload.type} is not supported. Please select a different payment type.`,
        });
        break;
    }

    if (this.paymentDetails.paymentType === TransactionTypeEnum.CreditCard) {
      this.transaction.creditCard = payload.details.lastFour;
      this.transaction.expirationMonth = parseInt(
        payload.details.expirationMonth
      );
      this.transaction.expirationYear = parseInt(payload.details.expirationYear);
      this.transaction.accountPersonName = payload.details.cardholderName;
      this.typeOut = payload.details.cardType;

      switch (payload.details.cardType) {
        case "American Express":
          this.transaction.creditCardType = CreditCardTypeEnum.Amex;
          break;
        case "Discover":
          this.transaction.creditCardType = CreditCardTypeEnum.Discover;
          break;
        case "MasterCard":
          this.transaction.creditCardType = CreditCardTypeEnum.Mastercard;
          break;
        case "Visa":
          this.transaction.creditCardType = CreditCardTypeEnum.Visa;
          break;
        default:
          this.transaction.creditCardType = null;
          break;
      }
    }

    if (this.paymentDetails.paymentType == TransactionTypeEnum.Venmo ||
      this.paymentDetails.paymentType == TransactionTypeEnum.PayPal ||
      this.paymentDetails.paymentType == TransactionTypeEnum.ApplePay) {
      this.transaction.deviceData = this._deviceDataCollector.deviceData;
    }

    this.transaction.donorId = this.csr.donorId;
    this.transaction.campaignId = this.csr.getCampaignId();
    this.transaction.hospitalId = this.csr.getHospitalId();
    if (this.csr.radioStationId !== 0) {
      this.transaction.radioStationNameId = this.csr.radioStationId;
    } else {
      this.transaction.radioStationNameId = null;
    }

    this.transaction.phoneDonationYn = false;
    this.transaction.donationSourceId = 3;
    this.transaction.donorSeed = this.csr.donorSeed;
    this.transaction.donorReferenceNumber = this.csr.donorReferenceNumber;

    this.transaction.transactionType = this.paymentDetails.paymentType;

    this.transaction.cmnhHospitalId = this.donorEditCommon.donor.cmnhHospitalId;

    if (this.transaction.chargeFrequency !== ChargeFrequencyEnum.OneTime) {
      this.transaction.dayToCharge = this._donationFrequency.dayOfMonth;
      if (
        this._donationFrequency.giftTerminationMonth !== null &&
        this._donationFrequency.giftTerminationYear !== null
      ) {
        this.transaction.monthToTerminate =
          this._donationFrequency.giftTerminationMonth;
        this.transaction.yearToTerminate =
          this._donationFrequency.giftTerminationYear;
      }
    }
    this.transaction.nonce = payload.nonce;
    this._toggleFrequencyLabel();
    this.handleOpenVerifyModal();
  }

  private _toggleFrequencyLabel() {
    this.frequency = this._common.getFrequencyText(
      this.transaction.chargeFrequency
    );
    switch (this.frequency) {
      case "One Time":
        this.frequency = this._language.translate("ONE_TIME");
        break;
      case "Monthly":
        this.frequency = this._language.translate("MONTHLY");
        break;
      case "Quarterly":
        this.frequency = this._language.translate("QUARTERLY");
        break;
      case "Semi Annual":
        this.frequency = this._language.translate("SEMI_ANNUAL");
        break;
      case "Yearly":
        this.frequency = this._language.translate("ANNUALLY");
        break;
    }
  }

  private _sendTransaction() {
    this.toggleSpinner(true);
    this._data.Donor.saveTransaction(this.transaction).subscribe(
      (result) => {
        var returnItem = result as IDonorTransactionBindingModel;
        if (returnItem.resultCode == 0) {
          this.csr.processRefNo = returnItem.processRefNo;
          this.csr.responseMsg = returnItem.responseMsg;
          this.csr.resultCode = returnItem.resultCode;
          this.csr.authCode = returnItem.authCode;
          this.csr.donationAmount = this.transaction.donationAmount;
          this.csr.chargeFrequency = this.transaction.chargeFrequency;

          this._gaPurchase("Credit Card");

          if (!this.notifySomeone && !this.inMemoryHonorOf) {
            this.handleOpenCreditCardECheckPaymentModal();
          } else {
            this._processGiftNOtifyAndHonor(
              returnItem.donorId,
              returnItem.donorTransactionId
            );
          }
          this.donateForm.resetForm();
        } else {
          this.csr.donationAmount = this.transaction.donationAmount;
          this.csr.chargeFrequency = this.transaction.chargeFrequency;
          this.csr.responseMsg = returnItem.responseMsg;
          this.handleOpenFailedCCECheckModal();
        }
        this.toggleSpinner();
        this.transactionComplete = true;
        this._common.donationProcessed(true);
      },
      (error) => {
        this.toggleSpinner();
        this._logger.logError({
          message: `Could not save the credit card payment<br />${this._common.jsonMessage(
            error
          )}`,
        });
      }
    );
  }

  private _gaPurchase(transactionType: string) {
    this._googleAnalytics.standardEventEmitter("purchase", {
      currency: "USD",
      transaction_id: Math.random().toString(36).substring(2, 25),
      value: this.transaction.donationAmount,
      items: [
        {
          id: this.transaction.campaignId.toString(),
          category: transactionType,
          brand:
            this.transaction.chargeFrequency === 2 ? "Recurring" : "One Time",
          name: "Donation",
          quantity: 1,
          price: this.transaction.donationAmount,
        },
      ],
    });

    // send DRTV purchase events to Disruptive Advertising
    if (this.drtv) {
      this._googleAnalytics.customEventParamsEmitter("conversion", {
        send_to: "AW-844820968/e34jCLmL2bIDEOjj65ID",
      });
    }
  }

  submitECheck() {
    this.transaction.donorId = this.csr.donorId;
    this.transaction.campaignId = this.csr.getCampaignId();
    this.transaction.hospitalId = this.csr.getHospitalId();
    if (this.csr.radioStationId !== 0) {
      this.transaction.radioStationNameId = this.csr.radioStationId;
    } else {
      this.transaction.radioStationNameId = null;
    }

    this.transaction.phoneDonationYn = false;
    this.transaction.donationSourceId = 3;
    this.transaction.donorSeed = this.csr.donorSeed;
    this.transaction.donorReferenceNumber = this.csr.donorReferenceNumber;

    // this.transaction.chargeFrequency = parseInt(
    //   this._donationFrequency.chargeFrequency
    // );
    this.transaction.transactionType = TransactionTypeEnum.ECheck;
    //this.transaction.displayInDash = true;

    if (this.transaction.chargeFrequency !== ChargeFrequencyEnum.OneTime) {
      this.transaction.dayToCharge = this._donationFrequency.dayOfMonth;
      if (
        this._donationFrequency.giftTerminationMonth !== null &&
        this._donationFrequency.giftTerminationYear !== null
      ) {
        this.transaction.monthToTerminate =
          this._donationFrequency.giftTerminationMonth;
        this.transaction.yearToTerminate =
          this._donationFrequency.giftTerminationYear;
      }
    }

    this._toggleFrequencyLabel();
    this.handleOpenVerifyeCheckPaymentModal();
  }

  continueWithCall(reloadPage: boolean): void {
    let path = `/donor/y/${this.csr.getCampaignId()}/${this.csr.getHospitalId()}/${
      this.csr.radioStationId
      }`;

    if (this.drtv) {
      path = `/helpkidstoday/${this.csr.getCampaignId()}/${this.csr.getHospitalId()}`;
    }

    if (reloadPage) {
      this.router.navigate([path]).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    } else {
      this.router.navigate([path]);
      const element = this.elementRef?.nativeElement?.closest(
        ".drtv-donate-section"
      );
      if (element)
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
    }
  }

  private _sendECheckTransaction() {
    var stateAbbrev = this.donorEditCommon.states.filter((state) => {
      return state.label === this.donorEditCommon.donor.state;
    })[0]["abrev"];

    this.toggleSpinner(true);
    this._usBankAccountInstance.tokenize(
      {
        bankDetails: {
          routingNumber: this.transaction.abaNumber,
          accountNumber: this.transaction.bankAccountNumber,
          accountType: this.transaction.isCheckingAccount
            ? "checking"
            : "savings",
          ownershipType: "personal",
          firstName: this.donorEditCommon.donor.firstName,
          lastName: this.donorEditCommon.donor.lastName,
          billingAddress: {
            streetAddress: this.donorEditCommon.donor.address1,
            extendedAddress: this.donorEditCommon.donor.address2,
            locality: this.donorEditCommon.donor.city,
            region: stateAbbrev,
            postalCode: this.donorEditCommon.donor.postalCode,
          },
        },
        mandateText:
          "By clicking Continue, I authorize Braintree, a service of PayPal, on behalf of Children's Miracle Network Hospitals(i) to verify my bank account information using bank information and consumer reports and(ii) to debit my bank account.",
      },
      (tokenizeErr, tokenizedPayload) => {
        if (tokenizeErr) {
          this.toggleSpinner();
          this._logger.logError({
            message: `Could not tokenize the bank details<br />${tokenizeErr}`,
          });
        } else {
          this.transaction.nonce = tokenizedPayload.nonce;
          this._data.Donor.saveTransaction(this.transaction).subscribe(
            (result) => {
              var returnItem = result as IDonorTransactionBindingModel;
              if (returnItem.resultCode == 0) {
                this.csr.processRefNo = returnItem.processRefNo;
                this.csr.responseMsg = returnItem.responseMsg;
                this.csr.resultCode = returnItem.resultCode;
                this.csr.authCode = returnItem.authCode;
                this.csr.donationAmount = this.transaction.donationAmount;
                this.csr.chargeFrequency = this.transaction.chargeFrequency;
                // this.csr.donorTransactionId = returnItem.donorTransactionId;
                // this.csr.bindGiftToInHonorInMemory();

                this._gaPurchase("eCheck");

                if (!this.notifySomeone && !this.inMemoryHonorOf) {
                  this.handleOpenCreditCardECheckPaymentModal();
                } else {
                  this._processGiftNOtifyAndHonor(
                    returnItem.donorId,
                    returnItem.donorTransactionId
                  );
                }
              } else {
                this.csr.donationAmount = this.transaction.donationAmount;
                this.csr.chargeFrequency = this.transaction.chargeFrequency;
                this.csr.responseMsg = returnItem.responseMsg;
                this.handleOpenFailedCCECheckModal();
              }
              this.toggleSpinner();
              this.donateForm.resetForm();
              this.transactionComplete = true;
              this._common.donationProcessed(true);
            },
            (error) => {
              this._logger.logError({
                message: `Could not save the eCheck payment<br />${this._common.jsonMessage(
                  error
                )}`,
              });
              this.toggleSpinner();
            }
          );
        }
      }
    );
  }

  private _checkIfAlreadyProcessed() {
    if (this.csr.resultCode !== -1) {
      this._common.$location.path("/").replace();
    }
  }

  private _processGiftNOtifyAndHonor(donorId: number, donorTransactionId) {
    const data = {} as IInHonorNotificationBindingModel;
    if (this.notifySomeone) {
      this.giftNotify.donorId = donorId;
      this.giftNotify.donorTransactionId = donorTransactionId;
      this.giftNotify.giftNotificationId = 0;
      data.giftNotificationModel = this.giftNotify;
    }
    if (this.inMemoryHonorOf) {
      this.honorModel.inHonorOf = this.honorDetails.inHonor;
      this.honorModel.donorId = donorId;
      this.honorModel.donorTransactionId = donorTransactionId;
      data.inHonorModel = this.honorModel;
    }
    if (data.inHonorModel || data.giftNotificationModel) {
      return this._data.Donor.saveInHonorGiftNotification(data).subscribe(
        () => {
          if (data.inHonorModel && !data.giftNotificationModel) {
            this._logger.logSuccess({
              message: "'In Honor/Memory Of' data has been saved",
            });
          }
          if (!data.inHonorModel && data.giftNotificationModel) {
            this._logger.logSuccess({
              message: "Gift Notification has been saved.",
            });
          }
          if (data.inHonorModel && data.giftNotificationModel) {
            this._logger.logSuccess({
              message:
                "Gift Notification and 'In Honor/Memory Of' data has been saved.",
            });
          }
          //callback();
          this.handleOpenCreditCardECheckPaymentModal();
        },
        (error) => {
          this._logger.logError({
            message: `Could not save the Gift NOtification or In Honor/Memory Of.<br />${this._common.jsonMessage(
              error
            )}`,
          });
          //callback();
          this.handleOpenCreditCardECheckPaymentModal();
        }
      );
    }
    return null;
  }

  // reCAPTCHA
  resolved(captchaResponse: string): void {
    this.recaptchaToken = captchaResponse;
  }

  errored(errorDetails: any) {
    console.warn(`reCAPTCHA error encountered: ${errorDetails}`);
  }

  trackModalEvent(actionType: string, label: string): void {
    this._googleAnalytics.customEventEmitter({
      eventName: `Splash_modal_${actionType}`,
      eventCategory: 'Modal',
      eventAction: actionType,
      eventLabel: label
    });
  }
}
