import { Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { CsrService } from "../csr/csr.service";
import { CommonService } from "../common/common.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: "app-header-bar",
  templateUrl: "./header-bar.component.html",
  styleUrls: ["./header-bar.component.scss"],
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  @Input() isHidden = false;
  lastScrollPosition = 0;
  isMobile: boolean = false;
  private scrollSubscription: Subscription | null = null;

  menuItems = [
    { title: 'Home', icon: 'fa fa-home', action: () => this.homeClicked(), hover: 'Back to home' },
    { title: 'About', icon: 'fa fa-bookmark', action: () => this.aboutClicked(), hover: '' },
    { title: 'Contact Us', icon: 'fa fa-phone', action: () => this.contactClicked(), hover: '' },
  ];

  constructor(
    private csrService: CsrService,
    private _commonService: CommonService,
    private router: Router,
    private _googleAnalytics: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.checkIfMobile();
    this.listenToScroll();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfMobile();
    if (!this.isMobile) {
      this.isHidden = false;
    }
    this.listenToScroll();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  listenToScroll() {
    this.unsubscribeScroll();
    if (this.isMobile) {
      this.scrollSubscription = fromEvent(window, 'scroll')
        .pipe(throttleTime(100))
        .subscribe(() => {
          const scrollPosition = window.pageYOffset;
          const threshold = 100;
          if (Math.abs(scrollPosition - this.lastScrollPosition) > threshold) {
            this.isHidden = scrollPosition > this.lastScrollPosition;
            this.lastScrollPosition = scrollPosition;
          }
        });
    }
  }

  private unsubscribeScroll() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
      this.scrollSubscription = null;
    }
  }

  getCampaignId(): number | null {
    return this.csrService.getCampaignId();
  }

  aboutClicked = (): void => {
    this.navigateWithTracking(['/about'], () => this._commonService.aboutShuffel(), "About");
  }

  contactClicked = (): void => {
    this.navigateWithTracking(['/contact'], () => this._commonService.contactShuffel(), "Contact");
  }

  homeClicked = (): void => {
    const campaignId = this.getCampaignId();
    this.navigateWithTracking(['/splash', campaignId !== null ? campaignId.toString() : ''], () => this._commonService.homeShuffel(), "Splash");
  }

  private navigateWithTracking(route: string[], action: () => void, event: string): void {
    action();
    this.trackItemSelected(event);
    setTimeout(() => {
      this.router.navigate(route);
    }, 1000);
  }

  ngOnDestroy() {
    this.unsubscribeScroll();
  }

  private trackItemSelected(event: string): void {
    this._googleAnalytics.customEventEmitter({
      eventName: event,
      eventCategory: 'Splash - Menu',
      eventAction: 'Click',
      eventLabel: 'User enter in Menu'
    });
  }
}
